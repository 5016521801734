import React from "react";
import htmlToImage from "html-to-image";

import { NavLink } from "../../core/router";
import "../../styles/generic.scss";
import "./CareFormDetailsPage.scss";

import Loading from "../../shared/Loading";
import Checkbox from "../../shared/Checkbox";
import { withMessages } from "../../shared/Messages";
import httpClient from "../../shared/utils/HttpClient";

import CareFormPdf from "./CareFormDetailsPage/CareFormPdf";
import ConditionCareForm from "./CareFormDetailsPage/ConditionCareForm";
import ConditionViewCareForm from "./CareFormDetailsPage/ConditionViewCareForm";
import CareFormAttestationPDF from "./CareFormDetailsPage/CareFormAttestationPDF";

import withValidation from "../../validations/withValidation";
import CareFormRules from "../../validations/FormRules/CareFormRules";

import consumeApp from "../../providers/consumeApp";
import ActivityTracker from "../../shared/ActivityTracker";

import { Storage } from "aws-amplify";

class CareFormDetailsPage extends React.Component {
    constructor(props) {
        super(props);
        this.displayName = "CareFormDetailsPage";
        this.state = {
            loading: false,
            loadingSubmit: false,
            signature: false,
            careForm: {}
        };
        this.attestationPDFref = React.createRef();
    }
    componentDidMount() {
        this.getCareForm();
    }
    getCareForm = () => {
        let apiName = "providerApi";
        let path = `/CareFormDetails(${this.props.match.params.id})`;
        this.setState({
            loading: true
        });
        httpClient().get(apiName, path)
            .then((data) => {
                this.setState({
                    loading: false,
                    careForm: data
                });
            }).catch((error) => {
                this.setState({
                    loading: false
                });
            });
    }
    handleChangeCodingGaps = (i) => (value) => {
        let codingGaps = [...this.state.careForm.CodingGaps];
        codingGaps[i] = value;

        this.setState({
            careForm: {
                ...this.state.careForm,
                CodingGaps: codingGaps
            }
        });

        //Trigger validation for new updates:
        this.props.validateLive({
            CodingGaps: codingGaps.filter((item) => item.status !== "Dismiss")
        });
    }
    handleChange = (name, value) => {
        this.setState({
            [name]: value
        });
    }
    savePdf = (data, activityLog) => {
        const { careForm } = this.state;

        //Put the log.eventId on the PDF:
        document.getElementById("btn-submit-careform-log-id").textContent = activityLog.eventId;
        htmlToImage.toPng(this.attestationPDFref.current)
            .then(async (attestationImg) => {
                let fileName = `care-form-${careForm.patientId}-${careForm.appointmentDate}.pdf`
                fileName = fileName.replace(/\//g, '-');

                CareFormPdf({
                    ...data,
                    attestationImg: attestationImg
                }).getBlob(function (blob) {

                    //Download PDF file:
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();

                    // Upload PDF file to S3
                    Storage.put(fileName, blob, {
                        level: 'protected',
                        contentType: 'application/pdf'
                    }).catch((error) => {
                        this.setState({
                            loadingSubmit: false
                        });
                        this.props.showMessage("warning", error);
                    });
                });

            });
    }
    saveCodingGaps = (data) => {

        let apiName = "providerApi";
        let path = `/CareFormDetails(${data.appointmentId})`;

        this.setState({
            loadingSubmit: true
        });
        let bodyData = {
            appointmentId: data.appointmentId,
            acceptedGaps: [],
            rejectedGaps: []
        };

        for (let i = 0; i < data.CodingGaps.length; i++) {
            if (data.CodingGaps[parseInt(i)].status === "Accept") {
                bodyData.acceptedGaps.push({
                    hccCode: data.CodingGaps[parseInt(i)].hccCode,
                    hccDescription: data.CodingGaps[parseInt(i)].hccDescription,
                    diagnosisCode: data.CodingGaps[parseInt(i)].diagnosisCode
                });
            } else if (data.CodingGaps[parseInt(i)].status === "Dismiss") {
                bodyData.rejectedGaps.push({
                    hccCode: data.CodingGaps[parseInt(i)].hccCode,
                    hccDescription: data.CodingGaps[parseInt(i)].hccDescription,
                    diagnosisCode: data.CodingGaps[parseInt(i)].diagnosisCode
                });
            }
        }

        httpClient().retries(2)
            .post(apiName, path, bodyData)
            .then((data) => {
                this.setState({
                    loadingSubmit: false,
                });
                this.props.showMessage("success", "Care form saved");
                this.props.history.push("/careforms/");
            }).catch((error) => {
                this.setState({
                    loadingSubmit: false
                });
                this.props.showMessage("warning", error);
            });
    }
    areAllCodingGapsRejected = () => {
        return this.state.careForm.CodingGaps && this.state.careForm.CodingGaps.filter((codingGap) => codingGap.status !== "Dismiss").length === 0;
    }
    shouldEnableSubmit = () => {
        return this.state.signature || this.areAllCodingGapsRejected();
    }
    handleSubmit = (event, activityLog) => {
        const { careForm } = this.state;
        const user = this.props.authUser;

        let data = {
            patientFirstName: careForm.patientFirstName,
            patientLastName: careForm.patientLastName,
            patientDOB: careForm.patientDOB,
            patientMRN: careForm.patientMRN,
            appointmentDate: careForm.appointmentDate,
            appointmentId: careForm.appointmentId,
            CodingGaps: careForm.CodingGaps.filter((item) => item.status !== "Dismiss"),
            providerFullName: user.fullName
        };

        if (!this.props.validate(data)) return;

        if (!this.areAllCodingGapsRejected()) {
            this.savePdf(data, activityLog);
        }
        this.saveCodingGaps(careForm);
    }
    render() {

        const { careForm } = this.state;
        const user = this.props.authUser ? this.props.authUser : {};

        return (
            <div id="careFormDetailsPage" className="container">
                <div className="level">
                    <div className="level-left" >
                        <div>
                            <NavLink to={"/careforms"}>
                                <i className="fas fa-chevron-left" />&nbsp;&nbsp;Back
                            </NavLink>
                        </div>
                    </div>
                </div>
                <div className="level">
                    <div className="level-left" >
                        <div>
                            <h1 className="title">Care Form</h1>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="panel has-background-white">
                    <div className="panel-block">
                        {this.state.loading ?
                            <div className="container is-fullwidth" >
                                <Loading />
                            </div>
                            : <div className="careform-details container is-fullwidth" >
                                <div className="level-left">
                                    <div className="level-item">
                                        Patient Name:&nbsp; <b>{careForm.patientFirstName} {careForm.patientLastName}</b>
                                    </div>
                                    <div className="level-item">
                                        MRN:&nbsp; <b>{careForm.patientMRN}</b>
                                    </div>
                                    <div className="level-item">
                                        Patient DOB:&nbsp; <b>{careForm.patientDOB}</b>
                                    </div>
                                </div>
                                <div>
                                    <hr />
                                A) List all current/active conditions for this date of service<br />
                                B) MUST provide brief treatment plan for all active conditions you manage and/or name and speciality of referred-to provider: (i.e. PX, PT, monitoring, etc)
                                <hr />
                                </div>
                                <table id="table-codings" className="table is-fullwidth is-bordered" >
                                    <tbody>
                                        <tr>
                                            <th className="b-n" >Condition Code / Description</th>
                                            <th className="b-n" >ICD10 Billing Code</th>
                                            <th className="b-n" >Treatment plan for condition managed by provider</th>
                                            {/* <th className="b-n" >Identify referred-to physician and speciality</th> */}
                                            <th className="b-n" >Status</th>
                                        </tr>
                                        {careForm.CodingGaps && careForm.CodingGaps.map((codingGap, i) =>
                                            codingGap.status !== "Dismiss" &&
                                            <ConditionCareForm
                                                key={codingGap.hccCode}
                                                onChange={this.handleChangeCodingGaps(i)}
                                                value={codingGap}
                                                validations={this.props.validations !== true && this.props.validations.CodingGaps[i] ? this.props.validations.CodingGaps[i] : true}
                                            />
                                        )}
                                        <tr>
                                            <td className="b-n" colSpan={5}>
                                                <div className="level">
                                                    <div className="level-item">
                                                        Provider: {user.fullName}
                                                    </div>
                                                    <div className="level-item">
                                                        Date of Service: {careForm.appointmentDate}
                                                    </div>
                                                    <div className="level-item">
                                                        Appointment: ID: {careForm.appointmentId}
                                                    </div>
                                                    <div className="level-item">
                                                        Provider Signature(check to accept): &nbsp;&nbsp;
                                                    <Checkbox
                                                            name="signature"
                                                            checked={this.state.signature}
                                                            onChange={this.handleChange}
                                                        />
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="b-n has-text-right" colSpan={5}>
                                                <ActivityTracker
                                                    immediate
                                                    tag="btn-submit-careform"
                                                    events={["click"]}
                                                    userId={this.props.authUser.attributes.sub}
                                                >
                                                    <button
                                                        id="submitCareForm"
                                                        className={`button is-primary is-small ${this.state.loadingSubmit ? "loadingSubmit" : ""}`}
                                                        onClick={this.handleSubmit}
                                                        disabled={!this.shouldEnableSubmit()}
                                                    >
                                                        Submit
                                                </button>
                                                </ActivityTracker>
                                            </td>
                                        </tr>
                                        {careForm.CodingGaps && careForm.CodingGaps.filter((item) =>
                                            item.status === "Dismiss").length > 0 &&
                                            <tr>
                                                <th className="b-n" colSpan={5} >
                                                    Dismissed Coding Gap
                                        </th>
                                            </tr>}
                                        {careForm.CodingGaps && careForm.CodingGaps.map((codingGap, i) =>
                                            codingGap.status === "Dismiss" &&
                                            <ConditionViewCareForm
                                                key={i}
                                                onChange={this.handleChangeCodingGaps(i)}
                                                value={codingGap}
                                            />
                                        )}
                                    </tbody>
                                </table>
                            </div>}
                    </div>
                </div>
                <CareFormAttestationPDF
                    ref={this.attestationPDFref}
                    physician_name={user.fullName}
                    physician_NPI={user.attributes["custom:NPI"]}
                    ip={this.props.webClientInfo.ip}
                />
            </div>
        );
    }
}
export default withMessages(consumeApp(withValidation(CareFormDetailsPage, CareFormRules)));