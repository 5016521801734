const imgToBase64 = (url, cb) => {
    var img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function () {
        var canvas = document.createElement('canvas'),
            ctx = canvas.getContext('2d');

        canvas.height = img.naturalHeight;
        canvas.width = img.naturalWidth;
        ctx.drawImage(img, 0, 0);
        cb(canvas.toDataURL('image/png'));
    };
    img.src = url;

};
export default imgToBase64;