const InactivityMonitor = {
    events: [
        "load",
        "mousemove",
        "mousedown",
        "click",
        "scroll",
        "keypress"
    ],
    warnTime: 14 * 60,
    logoutTime: 15 * 60,
    logoutAction: () => {},
    warnAction: () => {},
    onReset: () => {},
    setup: () => {
        for (var i in InactivityMonitor.events) {
            window.addEventListener(InactivityMonitor.events[i], InactivityMonitor.resetTimeout);
        }

        InactivityMonitor.setTimeout();
    },
    clearTimeout: () => {
        if (InactivityMonitor.warnTimeout){
            clearTimeout(InactivityMonitor.warnTimeout);
        }

        if (InactivityMonitor.logoutTimeout){
            clearTimeout(InactivityMonitor.logoutTimeout);
        }
    },
    setTimeout: () => {
        const {warnTime, logoutTime} = InactivityMonitor;
        InactivityMonitor.warnTimeout = setTimeout(InactivityMonitor.warn, warnTime * 1000);

        InactivityMonitor.logoutTimeout = setTimeout(InactivityMonitor.logout, logoutTime * 1000);
    },
    resetTimeout: () => {
        InactivityMonitor.clearTimeout();
        InactivityMonitor.setTimeout();
        if(InactivityMonitor.onReset){
            InactivityMonitor.onReset();
        }
    },
    warn: () => {
        if(InactivityMonitor.warnAction){
            InactivityMonitor.warnAction();
        }
    },
    logout: () => {
        if(InactivityMonitor.logoutAction){
            InactivityMonitor.logoutAction();
        }
        InactivityMonitor.destroy(); // Cleanup
    },
    destroy: () => {
        InactivityMonitor.clearTimeout();

        for (var i in InactivityMonitor.events) {
            window.removeEventListener(InactivityMonitor.events[i], InactivityMonitor.resetTimeout);
        }
    }
}
export default InactivityMonitor;