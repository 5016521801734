export default [
	{
		code: 'email',
		element_type: 'input',
		rules: {
			required: {
				type: 'error',
				error_type: 'hard',
				message: 'This field is required',
				rule: ['required'],
			},
			email: {
				type: 'error',
				error_type: 'hard',
				message: 'This field should be a valid email',
				rule: ['email'],
			}
		}
	},
	{
		code: 'phoneNumber',
		element_type: 'input',
		rules: {
			phone: {
				type: 'error',
				error_type: 'hard',
				message: 'This field should be a valid phone number',
				rule: ['phone'],
			},
			required: {
				type: 'error',
				error_type: 'hard',
				message: 'This field is required',
				rule: ['required'],
			}
		}
	},
	{
		code: 'oldPassword',
		element_type: 'input',
		rules: {
			required: {
				type: 'error',
				error_type: 'hard',
				message: 'Password cannot be empty.',
				rule: ['required'],
			},
			password:{
				type: 'error',
				error_type: 'hard',
				message: 'Password does not meet requirements.',
				rule: ['password']
			},
		}
	},
	{
		code: 'newPassword',
		element_type: 'input',
		rules: {
			required: {
				type: 'error',
				error_type: 'hard',
				message: 'Password cannot be empty.',
				rule: ['required'],
			},
			password:{
				type: 'error',
				error_type: 'hard',
				message: 'Password does not meet requirements.',
				rule: ['password']
			},
		}
	},
	{
		code: 'confirmNewPassword',
		element_type: 'input',
		rules: {
			required: {
				type: 'error',
				error_type: 'hard',
				message: 'Password cannot be empty.',
				rule: ['required'],
			},
			password:{
				type: 'error',
				error_type: 'hard',
				message: 'Password does not meet requirements.',
				rule: ['password']
			},
		}
	},
	
];
