import React from 'react';
import HttpClient from '../../shared/utils/HttpClient';
import {NavLink} from '../../core/router';
import Loading from '../../shared/Loading';
import InputSearch from '../../shared/InputSearch';

class CareFormsPage extends React.Component{
    constructor(props){
        super(props);
        
        this.state = {
            loading: false,
            careForms: [],
            userToken: null,
        };
    }
    componentDidMount(){
        this.getCareForms();
    }
    getCareForms = () => {
        let apiName = 'providerApi';
        let path = '/CareForms'; 

        this.setState({
            loading: true
        });
        HttpClient().get(apiName, path)
        .then( (data) => {
            this.setState({
                loading: false,
                careForms: data
            });
        }).catch((error) => {
            this.setState({
                loading: false
            });
        });
    }
    handleSearch = (name, value) => {
        this.setState({
            [name]: value
        });
    }
    filterCareForms = () => {
        return this.state.careForms.filter((careForm)=>{
            if(this.state.search === "" || this.state.search === null || this.state.search === undefined) {
                return true;
            }

            return (careForm.patientFirstName.toLowerCase()
                + careForm.patientLastName.toLowerCase()
                + careForm.patientDOB
                + careForm.patientMRN
                + careForm.appointmentDate).includes(this.state.search.toLowerCase())
        });
    }
    openCareForm = (appointmentId) => (event) =>{
        return this.props.history.push('/careforms/'+appointmentId);
    }
    render(){
        return(
            <div id="careFormsPage" className="container is-mobile">
                <div className="level">
                    <div className="level-left" >
                        <div>
                            <h1 className="title">Care Forms</h1>
                            <h2 className="subtitle">{this.state.careForms.length} forms needing signature</h2>
                        </div>
                    </div>
                    <div className="level-right" >
                        <div className="level-item" >
                            <InputSearch value={this.state.search} name="search" onChange={this.handleSearch} />
                        </div>
                    </div>
                </div>

                <div className="panel has-background-white">
                    <div className="panel-block">
                        <table className="table is-fullwidth is-hoverable">
                            <thead>
                                <tr>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Date of Birth</th>
                                    <th>MRN</th>
                                    <th>Date of Service</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                            {this.state.loading ?
                                <tr id="loading-row" >
                                    <td colSpan={5} >
                                        <Loading />
                                    </td>
                                </tr>
                            : this.filterCareForms().map((careForm) => 
                                <tr className="careForm" key={careForm.appointmentId} onClick={this.openCareForm(careForm.appointmentId)}>
                                    <td>{careForm.patientFirstName}</td>
                                    <td>{careForm.patientLastName}</td>
                                    <td>{careForm.patientDOB}</td>
                                    <td>{careForm.patientMRN}</td>
                                    <td>{careForm.appointmentDate}</td>
                                    <td>
                                        <NavLink to={"/careforms/"+careForm.appointmentId} >
                                            Open Care Form <i className="fas fa-chevron-right" />
                                        </NavLink>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}
export default CareFormsPage;
