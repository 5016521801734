import React from 'react';
import AppContext from './AppContext';

function consumeApp(WrappedComponent){
    return class extends React.Component{
        constructor(props){
            super(props);
            this.state={};
        }
        render(){
            
            return (
                <AppContext.Consumer>
                    {(context) => 
                        <WrappedComponent {...context} {...this.props} />
                    }
                </AppContext.Consumer>
            );
        }
    };
}
export default consumeApp;