import React from 'react';
import ReactLoading from "react-loading";

const Loading = (props) => {
    return (
        <div className={'level '+props.wrapperClass}>
            <div className={'level-item'}>
                <ReactLoading 
                    type={props.type}
                    delay={props.delay}
                    width={props.width}
                    height={props.height}
                    color={props.color}
                />
            </div>
        </div>

    );
}
Loading.defaultProps= {
    type: "spokes",
    delay: 0,
    width: 34,
    height: 34,
    color:"#007bff",
    wrapperClass:""
};
export default Loading;