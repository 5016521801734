import React from 'react';
import ReactDOM from 'react-dom';
import imgToBase64 from '../../../shared/utils/imgToBase64';
import checkMark from '../../../images/checkMark.png';
let checkMark64 = null;
imgToBase64(checkMark, (b64) => { checkMark64 = b64; });

const CareFormAttestationPDF = React.forwardRef((props, divRef) => {

    const {physician_name, physician_NPI, ip} = props;

    const style = {
        attestationImgHtml: {
            position: 'absolute',
            zIndex: -100,
            top: '0px',
        },
        unvisibleDiv: {
            position: 'relative',
        },
        attestationDomSignature: {
            width: '758px',
        },
        attestationContainer: {
            float: 'right',
            backgroundColor: '#0090D9', 
            padding: '10px',
        },
        imgContainer: {
            float: 'left',
        },
        textContainer: {
            float: 'left',
            color: '#000',
            fontSize: '8px',
            marginLeft: '10px',
        },
        text: {
            color: '#000',
            fontSize: '8px',
        },
        clear: {
            clear: "both",
        }
    };
    return !window.isUnitTest && ReactDOM.createPortal(
        <div style={style.attestationImgHtml} >
            <div style={style.unvisibleDiv} >
                <div ref={divRef} style={style.attestationDomSignature} >
                    <div style={style.attestationContainer} >
                        <div style={style.imgContainer} >
                            <img src={checkMark64} alt="Check mark" onLoad={props.onReady} />
                        </div>
                        <div style={style.textContainer} >
                            Approved by<br/>
                            {physician_name}<br/>
                            NPI: {physician_NPI}<br/>
                            IP: {ip}<br/>
                            Date: {(new Date()).toISOString()}<br/>
                        </div>
                        <div style={style.clear} />
                        <div style={style.text}>
                            Event ID: <span id="btn-submit-careform-log-id"></span>
                        </div>
                    </div>
                    <div style={style.clear} />
                </div>
            </div>
        </div>, 
        document.body
    );
});
CareFormAttestationPDF.displayName = "CareFormAttestationPDF";
export default CareFormAttestationPDF;