class NormalDecorator {
    response(data){
        return data;
    }
    postData(data){
        return data;
    }
    queryParams(data){
        return data;
    }
}
export default NormalDecorator;