import NormalDecorator from './NormalDecorator';
import ODataDecorator from './ODataDecorator';

function HttpClientDecorator(format){
    let docorator;
    switch(format){
        case "normal":
            docorator = new NormalDecorator();
        break;
        case "odata":
        default:
            docorator = new ODataDecorator();
        break;
    }

    return docorator;
}
export default HttpClientDecorator;