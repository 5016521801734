import React from 'react';
import {withRouter} from 'react-router-dom';
import NavLink from '../../core/router/NavLink';
import consumeApp from '../../providers/consumeApp';


class Header extends React.Component{
    constructor(props){
        super(props);
        this.state = {};
    }
    handleLogout = () => {
        this.props.logout();
    }
    render(){
        return(
        <nav className="navbar is-spaced" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
                <NavLink className="navbar-logo" to="/" >
                    <img src="../../docOS.350.150.dark.png" width="150" height="65" alt="docOS logo"/>
                </NavLink>
                <a href="#menu" role="button" className="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarMain">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>
            <div id="navbarMain" className="navbar-menu">
                <div className="navbar-start"></div>
                <div className="navbar-end">
                    <NavLink className="navbar-item" to="/profile" >Profile</NavLink>
                    <div className="navbar-item">
                        <div className="buttons">
                            <button className="button is-outlined is-link" onClick={this.handleLogout} >
                                Log out
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
        );
    }
}
export default consumeApp(withRouter(Header));