class User {
    constructor(CognitoUser){
        this.user = CognitoUser;
    }
    get cognitoUser(){
        return this.user;
    }
    get sub(){
        return this.user.attributes.sub;
    }
    get attributes(){
        return this.user.attributes;
    }
    get username(){
        return this.user.username;
    }
    get challengeName(){
        return this.user.challengeName;
    }
    get fullName() {
        if (this.user.attributes["custom:credential"]){
            return this.user.attributes.given_name + " " + this.user.attributes.family_name + ", " + this.user.attributes["custom:credential"];
        }
        return this.user.attributes.given_name + " " + this.user.attributes.family_name;
    }
}
export default User;