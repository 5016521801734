import React from 'react';
import withMessages from './withMessages';
import './es_alert.scss';

class Messages extends React.Component{
	getIcon(msg_type){
		switch(this.props.icons){
			case "fontawsome":
				return this.fontAwsomeIcon(msg_type);
			case "material":
            default:
				return this.materialIcon(msg_type);
			
		}
	}
	fontAwsomeIcon(msg_type){
		switch(msg_type){
			
			case "close":
				return (<i className={"fas fa-times"} />);
			
			case "danger":
				return (<i className={"far fa-times-circle"} />);
			
			case "warning":
				return (<i className={"fas fa-exclamation-triangle"} />);
			
			case "success":
			default:
				return (<i className={"fas fa-info-circle"} />);
		}
	}
	materialIcon(msg_type){
		switch(msg_type){
			
			case "close":
				return (<i className={"material-icons"}>close</i>);
			
			case "danger":
				return (<i className={"material-icons"}>error_outline</i>);
			
			case "warning":
				return (<i className={"material-icons"}>warning</i>);
			
			case "success":
			default:
				return (<i className={"material-icons"}>info</i>);
		}
	}
	dismissTimeout(msg){
		if(['danger', 'warning'].indexOf(msg.type) > -1) return null
		setTimeout(()=>{
			this.props.dismiss(msg.uuid);
		}, this.props.timeout)
		
		return null;
	}
	dismissNow = (uuid) => (event) => {
		this.props.dismiss(uuid);
	}
	render(){
		const positionClass = this.props.position.toLowerCase();
		const {messages} = this.props;
		
		return(
		<div className={"es-alert-container "+positionClass} >
			{messages.map( (msg, i) => i < this.props.maxStack &&
			<div className={"es-alert border-animated border-"+msg.type} key={"msg-alert-"+i} >
				<div className={"es-alert-icon has-text-"+msg.type}>
					{this.getIcon(msg.type)}
				</div>
				<div className="es-alert-msg">
					{msg.message}
				</div>
				<div className="es-alert-close" onClick={this.dismissNow(msg.uuid)} >
					{this.getIcon('close')}
				</div>
				{this.props.timeout && this.dismissTimeout(msg)}
			</div>)}
		</div>
		);
	}
}
Messages.defaultProps = {
	position: "bottom right",
	timeout: 5000, //ms
	maxStack: 5,
	icons: "fontawsome", //"material",//"fontawsome"
};
export default withMessages(Messages);
