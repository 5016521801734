const awsconfig = {
    "storage": sessionStorage,
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:b6289aed-184b-41dc-a2dd-8d145800b1f9",
    "aws_cognito_region": "us-east-2",
    "aws_user_files_s3_bucket": "provxp-care-forms",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_user_pools_id": "us-east-2_NxRcNo66h",
    "aws_user_pools_web_client_id": "71u9ma0t1n4fl6mc9nutu0qtuh",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "providerApi",
            "endpoint": "https://phuwqzux99.execute-api.us-east-1.amazonaws.com/Prod/",
            "region": "us-east-1"
        }
    ]
};

export default awsconfig;