import NormalDecorator from "./NormalDecorator";

class ODataDecorator extends NormalDecorator{
    response(data){
        if(!data){
            return null;
        }
        
        return data.d.results;
    }
    postData(data){
        return {
            d: {
                results: data
            }
        };
    }
}
export default ODataDecorator;