export default [
    {
        element_type: "arrayComplex",
        code: "CodingGaps",
        inputs: [
            {
                code: "icd10",
                element_type: "input",
                rules: {
                    required: {
                        message: "This field is required",
                        rule: ["required"],
                    },
                    customRegexICD10: {
                        message: "Invalid ICD-10 Code",
                        rule: ["customRegexICD10"],
                    },
                }
            },
            {
                code: "treatmentPlan",
                element_type: "input",
                rules: {
                    required: {
                        message: "This field is required",
                        rule: ["required"],
                    },
                }
            },
            {
                code: "treatmentPlanNotes",
                element_type: "input",
                rules: {
                    requiredOnOther: {
                        message: "This field is required",
                        rule: ["customRule", (value, data, Rules) => {
                            if(data.treatmentPlan && data.treatmentPlan.toLowerCase() === 'other'){
                                return Rules.required(value, data);
                            }
                            return true;
                        }],
                    },
                }
            },
            // {
            //     code: "referralName",
            //     element_type: "input",
            //     rules: {
            //         required: {
            //             message: "This field is required",
            //             rule: ["required"],
            //         },
            //     }
            // },
            // {
            //     code: "referralSpeciality",
            //     element_type: "input",
            //     rules: {
            //         required: {
            //             message: "This field is required",
            //             rule: ["required"],
            //         },
            //     }
            // },
            {
                code: "status",
                element_type: "input",
                rules: {
                    required: {
                        message: "This field is required",
                        rule: ["required"],
                    },
                }
            }
        ],
    }
];