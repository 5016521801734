import React from 'react';

const Modal = (props) => {
    return(
    <div className={`modal ${props.isOpen ? "is-active":""}`}>
        <div className="modal-background" onClick={props.onClose}></div>
        <div className="modal-content">
            {props.children}
        </div>
        <button className="modal-close is-large" aria-label="close" onClick={props.onClose} ></button>
    </div>
    );
};
export default Modal;