import React from 'react';
import PropTypes from 'prop-types';
import './checkbox.scss';

class Checkbox extends React.Component{
    constructor(props){
        super(props);
        this.state = {};
    }
    handleChange = (event) => {
        this.props.onChange(this.props.name, !this.props.checked, this.props.value);
    }
    render(){
        return(
            <label className={"checkbox "+this.props.className}>
                <input 
                    type="checkbox" 
                    onChange={this.handleChange} 
                    checked={this.props.checked} 
                    value={this.props.value} 
                    name={this.props.name}
                /> 
                &nbsp;{this.props.label}
            </label>
        );
    }
}
Checkbox.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
};
export default Checkbox;
