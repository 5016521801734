import Rules from './Rules';

const Validate = {
	check: (rules, values) => {
		if (!values) return true;

		let validations = {};
		rules.map( (el) => {
			if(el.element_type === 'arrayComplex'){
				let arrayComplexValidations = [];
				let itemComplexValidation = null;
				values[el.code].map((itemComplex, i)=>{
					itemComplexValidation = Validate.check(el.inputs, itemComplex);
					if(itemComplexValidation !== true){
						arrayComplexValidations[i] = itemComplexValidation;
					}
					return itemComplex;
				});
				if(arrayComplexValidations.length > 0){
					validations[el.code] = arrayComplexValidations;
				}
			}else if(el.element_type === 'complex'){
				let complexValidations = Validate.check(el.inputs, values[el.code]);
				if(complexValidations !== true){
					validations[el.code] = complexValidations;
				}
			}else{
				Object.keys(el.rules).map( (ruleIdentity) => {
				
					let ruleName 		= el.rules[ruleIdentity].rule[0]; //RuleName Ex: Rules.{RuleName}
					let args 			= el.rules[ruleIdentity].rule.slice(1);
					let currentValue 	= values[el.code];
	
					if( Rules[ruleName](currentValue, values, ...args) === false && ruleName === 'required' ){
						validations[el.code] = el.rules[ruleIdentity];
					}else{
						if( Rules[ruleName](currentValue, values, ...args) === false && ( ruleName === 'customRule' || (ruleName !== 'required' && !empty(currentValue)) ) ){
							validations[el.code] = el.rules[ruleIdentity];
						}
					}
					
					return ruleIdentity;
				});
			}

			return el;

		});

		if (Object.keys(validations).length < 1) validations = true;

		return validations;
	},
};
export default Validate;

function empty(v, trim = false) {
	/*
	 * Value '0'(zero) is NOT empty
	 * */

	if (Array.isArray(v) && v.length === 0) {
		return true;
	} else if (Array.isArray(v) && v.length > 0) {
		return false;
	}

	if (trim && typeof v === 'string') {
		v = v.trim();
	}
	if (typeof v === 'undefined' || v === null || v === '' || (typeof v === 'object' && Object.keys(v).length === 0)) {
		return true;
	}
	return false;
}