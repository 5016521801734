import React from 'react';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import htmlToPdfmake from "html-to-pdfmake";
import { renderToString } from 'react-dom/server';
import docOSRiskAcuityLogo from '../../../images/docOSRiskAcuityLogo.png';
import imgToBase64 from '../../../shared/utils/imgToBase64';
let docOSRiskAcuityLogo64 = null;
imgToBase64(docOSRiskAcuityLogo, (b64) => { docOSRiskAcuityLogo64 = b64; });

/**
 * To generate PDF is used pdfMake
 * Documentation: https://pdfmake.github.io/docs/getting-started/client-side/methods/
 * we can get: blob, base64 or download it locally.
 */

pdfMake.vfs = pdfFonts.pdfMake.vfs;
const CareFormPdf = (props) => {
    
    var html = htmlToPdfmake(`${renderToString(CareFormTemplatePdf(props))}`);

    const style = {
        header: {
            bold: true,
            fontSize: 20,
        },
        'text': {
            fontSize: 8,
        },
        'mt-20': {
            marginTop: 20,
        },
        'mb-20': {
            marginBottom: 20,
        },
        'ml-20': {
            marginLeft: 20,
        },
        'mr-20': {
            marginRight: 20,
        },
        'attestationBlock':{

        },
        'attestation': {
            float: 'right',
            color: '#ccc',
            fontSize: 7,
            width: 200,
            display: 'inline',
        },
        inline: {
            display: 'inline',
        },
        attestationImg: {
            width: 64,
            display: 'inline',
            float: 'left'
        },
        table: {
            backgroundColor: '#fff',
            color: '#363636',
            borderCollapse: 'collapse',
            borderSpacing: 0,
            width: 758,
            border: [false, false, false, false],
            marginTop: 20,
        },
        th: {
            fontSize: 9,
            bold: true,
            textAlign: 'center',
            margin: [10, 10, 10, 10],
            verticalAlign: 'middle',
            border: [false, false, false, false],
            backgroundColor: '#FFFFFF',
        },
        td: {
            fontSize: 8,
            textAlign: 'left',
            margin: [5, 5],
            verticalAlign: 'middle',
            // border: '1px solid #dbdbdb',
            border: [false, false, false, false],
        },
        lastCell:{
            width: 300,
        },
        noBorder: {
            border: 'none !important',
        }
    };

    var docDefinition = {
        content: html,
        styles: style,
        pageOrientation: 'landscape',
        defaultStyles: {
            b: {bold:true},
            strong: {bold:true},
            u: {decoration:'underline'},
            s: {decoration: 'lineThrough'},
            em: {italics:true},
            i: {italics:true},
            h1: {fontSize:24, bold:true, marginBottom:5},
            h2: {fontSize:22, bold:true, marginBottom:5},
            h3: {fontSize:20, bold:true, marginBottom:5},
            h4: {fontSize:18, bold:true, marginBottom:5},
            h5: {fontSize:16, bold:true, marginBottom:5},
            h6: {fontSize:14, bold:true, marginBottom:5},
            a: {color:'blue', decoration:'underline'},
            strike: {decoration: 'lineThrough'},
            p: {margin:[0, 5, 0, 10]},
            ul: {marginBottom:5},
            li: {marginLeft:5},
            table: {marginBottom:40},
            th: {bold:true, fillColor:'#EEEEEE', padding: '20px 40px'},
            td: {padding: '20px 40px'}
          }
    };
    
    return pdfMake.createPdf(docDefinition);
};
export default CareFormPdf;

const CareFormTemplatePdf = (props) => { 
    const hrStyle = JSON.stringify({
        left: 0, // the left position
        width: 758, // should be OK with a A4 page(514 portrait)
        color: 'black', // the color of the line
        thickness: 0.5, // how thick the line must be
        margin: [0, 12, 0, 12] // same order as PDFMake, meaning: [left, top, right, bottom]
    });

    const hr1Style = JSON.stringify({
        ...JSON.parse(hrStyle),
        color: '#999999',
        thickness: 1.5,
    });
    
    return(
        <div id="pdfcontent" className="careform-details container is-fullwidth" >
            <div className="mb-20" >
                <img width={120} src={docOSRiskAcuityLogo64} alt="DocOS Risk Acuity | logo" title="DocOS Risk Acuity" />
            </div>
            <div className={'header'} >Care Form</div>
            <hr data-pdfmake={hr1Style} />
            <div className={'text'} >
                <div className={'mr-20'} >
                    Patient Name: {props.patientFirstName} {props.patientLastName}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
                <div className={'mr-20'} >
                    MRN: {props.patientMRN}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
                <div className={'mr-20'} >
                    Patient DOB: {props.patientDOB}
                </div>
            </div>
            <hr data-pdfmake={hrStyle} />
            <div className='text' >
                <p>A) List all current/active conditions for this date of service</p><br />
                <p>B) MUST provide brief treatment plan for all active conditions you manage and/or name and speciality of referred-to provider: (i.e. PX, PT, monitoring, etc)</p>
            </div>
            <hr data-pdfmake={hrStyle} />
            <table className={'table'} >
                <tr>
                    <td className={'th'} >Condition Code / Description</td>
                    <td className={'th'} >
                        ICD10 Billing Code
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </td>
                    <td className={'th'} >
                        Treatment plan for condition managed by provider
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </td>
                    {/* <td className={'th'} >Identify referred-to physician and speciality</td> */}
                </tr>
                {props.CodingGaps.map((codingGap, i) => 
                    <tr key={codingGap.hccCode} >
                        <td className={'td'} >{codingGap.hccCode}: {codingGap.hccDescription}</td>
                        <td className={'td'} >{codingGap.icd10}</td>
                        <td className={'td'} >
                            {codingGap.treatmentPlan}<br/>
                            {codingGap.treatmentPlanNotes}
                        </td>
                        {/* <td className={'td'} >
                            {codigGap.referralName}<br/>
                            {codigGap.referralSpeciality}
                        </td> */}
                    </tr>
                )}
            </table>
            <div className={'mt-20 text'} >
                <div className={'ml-20'} >
                    Provider: {props.providerFullName}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
                <div className={'ml-20'} >
                    Date of Service: {props.appointmentDate}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
                <div className={'ml-20'} >
                    Appointment: ID: {props.appointmentId}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
                {/* <div >
                    Provider Signature: Date {moment().format("MM/DD/YYYY")}
                </div> */}
            </div>
            
            <hr data-pdfmake={hrStyle} />
            {/* <div className="attestationBlock" >
                <span className="attestation" >
                    Approved by<br/>
                    {physician_name}<br/>
                    NPI: {physician_NPI}<br/>
                    IP: {ip}<br/>
                    Date: {(new Date()).toISOString()}
                </span>
            </div> */}

            <img width={758} src={props.attestationImg} alt="Check mark" className="inline" title="Check mark" />

        </div>
    )
};