import React from "react";
import AppContext from "./AppContext";
import {Auth} from "aws-amplify";
import {getUserClientInfo} from "../shared/utils/webclient";
import User from "../shared/utils/User";
import HttpClient from '../shared/utils/HttpClient';
import InactivityMonitor from "../shared/InactivityMonitor";
import InactivityAlert from "../shared/InactivityAlert";
import ActivityUtils from '../shared/ActivityTracker/ActivityUtils';


class AppProvider extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            isLoggedIn: false,
            authUser: null,
            checkingAuthentication: true,
            webClientInfo: {},
            isOpenInactivityAlert: false
        };
    }
    getWebClientInfo = () => {
        getUserClientInfo((webClientInfo) => {
            this.setState({webClientInfo});
        });
    }
    autoLogin = (callback = null) => {
        //1. check if credentials are setted
        if( localStorage.getItem("amplify-signin-with-hostedUI")){
            if(callback !== null){
                callback();
            }
            setTimeout(()=>{
                this.checkCurrentUser();
            }, 300);
        }else{
            //re-check after a 300 ms:
            setTimeout(()=>{
                this.autoLogin(callback); 
            }, 300);
        }
    }
    inactivityAutoLogout = () => {
        InactivityMonitor.warnTime = 14 * 60; // seconds
        InactivityMonitor.logoutTime = 15 * 60; // seconds
        InactivityMonitor.logoutAction = this.logout;
        InactivityMonitor.warnAction = () => { 
            //warn action: none
            this.setState({
                isOpenInactivityAlert: true
            });
        };
        InactivityMonitor.onReset = () => {
            this.setState({
                isOpenInactivityAlert: false
            });
        };
        InactivityMonitor.setup();
    }
    checkCurrentUser = () => {
                        
        this.setState({checkingAuthentication: true});
        
        Auth.currentAuthenticatedUser({
            bypassCache: true
        })
        .then( (user) => {
            this.login(user);
        })
        .catch((error) => {
            this.setState({
                isLoggedIn: false,
                checkingAuthentication: false
            });
        });
    }
    login = (user) => {
        this.setState({
            isLoggedIn: true, 
            authUser: new User(user),
            checkingAuthentication: false
        });
        this.activitySyncSetup(user);
        this.inactivityAutoLogout();
    }
    logout = () => {
        Auth.signOut()
        .then( (data) => {
            this.setState({
                isLoggedIn: false, 
                authUser: null,
                isOpenInactivityAlert: false
            });
        });
    }
    toggleInactivityAlert = () => {
        this.setState({
            isOpenInactivityAlert: !this.state.isOpenInactivityAlert
        });
    }
    activitySyncSetup = (authUser) => {
        if(authUser.attributes.sub){
            ActivityUtils.syncSetup(authUser.attributes.sub, (activityLogs, failCallback) => {
                let apiName = 'providerApi';
                let path = `/Clickstream`; 
                HttpClient().post(apiName, path, activityLogs)
                .then( (response) => {})
                .catch( (error) => {
                    //Enqueue again logs into the storage to retry again next tick
                    failCallback()
                });
            });
        }
    }
    render() {
        return (
            <AppContext.Provider
                value={{
                    isLoggedIn: this.state.isLoggedIn,
                    authUser: this.state.authUser,
                    checkingAuthentication: this.state.checkingAuthentication,
                    webClientInfo: this.state.webClientInfo,
                    login: this.login,
                    logout: this.logout,
                    autoLogin: this.autoLogin,
                    checkCurrentUser: this.checkCurrentUser,
                    getWebClientInfo: this.getWebClientInfo,
                }}
            >   
                <InactivityAlert 
                    isOpenInactivityAlert={this.state.isOpenInactivityAlert} 
                    onClose={this.toggleInactivityAlert} 
                    startCounter={InactivityMonitor.logoutTime - InactivityMonitor.warnTime - 1}
                />
                {this.props.children}
            </AppContext.Provider>
        );
    }
}
export default AppProvider;