import React from 'react';
import MessagesContext from './MessagesContext';

function withMessages(WrappedComponent){
    return class withMessages extends React.Component{
        constructor(props){
            super(props);
            this.state={};
        }
        render(){
            return (
                <MessagesContext.Consumer>
                    {(context) => 
                        <WrappedComponent {...context} {...this.props} />
                    }
                </MessagesContext.Consumer>
            );
        }
    };
}
export default withMessages;