import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import consumeApp from '../../providers/consumeApp';

const MyRoute = (props) => {
	
	let componentRouteProps = Object.assign({}, props);
	let routeProps = {};
	
	if(typeof props.path !== 'undefined'){
		routeProps.path = props.path;
		delete componentRouteProps.path;
	}
	if(typeof props.exact !== 'undefined'){
		routeProps.exact = props.exact;
		delete componentRouteProps.exact;
	}
	if(typeof props.strict !== 'undefined'){
		routeProps.strict = props.strict;
		delete componentRouteProps.strict;
	}
	if(typeof props.location !== 'undefined'){
		routeProps.location = props.location;
		delete componentRouteProps.location;
	}
	if(typeof props.sensitive !== 'undefined'){
		routeProps.sensitive = props.sensitive;
		delete componentRouteProps.sensitive;
	}
	if(typeof props.computedMatch !== 'undefined'){
		routeProps.computedMatch = props.computedMatch;
		delete componentRouteProps.computedMatch;
	}
	if(typeof props.component !== 'undefined'){
		delete componentRouteProps.component;
	}
	if(typeof props.children !== 'undefined'){
		delete componentRouteProps.children;
	}
	
	let componentProps = {};
	Object.keys(componentRouteProps).map( (property) => {
		if( ['title'].indexOf(property) < 0 ){
			componentProps[`${property}`] = componentRouteProps[`${property}`];
			delete componentRouteProps[`${property}`];
		}
		return property;
	});
	const addReferer = () => {
		return '?ref='+encodeURIComponent(btoa(window.location.pathname));
	};
	
	return (
		props.children ?
		<Route {...routeProps} 
			render={(renderProps) => 
				props.private && !props.isLoggedIn ? 
					<Redirect to={props.redirectTo+addReferer()} /> 
				: props.onlyPublic && props.isLoggedIn ?
					<Redirect to={props.redirectTo} /> 
				: React.createElement(props.component, {...renderProps, route: componentRouteProps, ...componentProps}, props.children )} 
		/>
		:<Route {...routeProps} 
			render={(renderProps) => 
				props.private && !props.isLoggedIn ? 
					<Redirect to={props.redirectTo+addReferer()} />
				: props.onlyPublic && props.isLoggedIn ?
					<Redirect to={props.redirectTo} /> 
				: React.createElement(props.component, {...renderProps, route: componentRouteProps, ...componentProps})} 
		/>
	);
};
export default consumeApp(MyRoute);