import { API } from 'aws-amplify';
import HttpClientDecorator from './HttpClient/HttpClientDecorator';
import Endpoints from './HttpClient/Endpoints';

function HttpClient(){
    this.decorator = new HttpClientDecorator();
    this.max_retries = 0;
    this.attempts_counter = 0;
    this.useFormat = (format) => {
        this.decorator = new HttpClientDecorator(format);
        return this;
    };
    this.retries = (max_retries = 0 ) => {
        this.max_retries = max_retries;
        return this;
    };
    this.shouldRetry = () => {
        if(this.max_retries > this.attempts_counter){
            this.attempts_counter++;
            return true;
        }
        return false;
    };
    this.get = function(apiName, path, queryStringParameters = null, options = {}){
        path = Endpoints.buildPath(path);
        if(queryStringParameters){
            options.queryStringParameters = this.decorator.queryParams(queryStringParameters);
        }
        return (new Promise((resolve, reject) => {
            API.get(apiName, path, options)
            .then((response) => {
                resolve(this.decorator.response(response));
            })
            .catch( async (error) => {
                if(this.shouldRetry()){
                    try{
                        await this.get(...arguments);
                    }catch(e){
                        error = e;
                    }
                }
                reject(error);
                
            });
        }));
    };
    this.post = function(apiName, path, data = null, options = {}){
        path = Endpoints.buildPath(path);
        if(data){
            options.body = this.decorator.postData(data);
        }
        return (new Promise((resolve, reject) => {
            API.post(apiName, path, options)
            .then((response) => {
                resolve(this.decorator.response(response));
            })
            .catch( async (error) => {
                if(this.shouldRetry()){
                    try{
                        await this.post(...arguments);
                    }catch(e){
                        error = e;
                    }
                }

                reject(error);
            });
        }));

    };
    this.patch = function(apiName, path, data = null, options = {}){
        path = Endpoints.buildPath(path);
        if(data){
            options.body = this.decorator.postData(data);
        }
        return (new Promise((resolve, reject) => {
            API.patch(apiName, path, options)
            .then((response) => {
                resolve(this.decorator.response(response));
            })
            .catch( async (error) => {
                if(this.shouldRetry()){
                    try{
                        await this.patch(...arguments);
                    }catch(e){
                        error = e;
                    }
                }
                reject(error);
            });
        }));
    };
    this.put = function(apiName, path, data = null, options = {}){
        path = Endpoints.buildPath(path);
        if(data){
            options.body = this.decorator.postData(data);
        }
        return (new Promise((resolve, reject) => {
            API.put(apiName, path, options)
            .then((response) => {
                resolve(this.decorator.response(response));
            })
            .catch( async (error) => {
                if(this.shouldRetry()){
                    try{
                        await this.put(...arguments);
                    }catch(e){
                        error = e;
                    }
                }
                reject(error);
            });
        }));
    };
    this.delete = function(apiName, path, options = {}){
        path = Endpoints.buildPath(path);
        return (new Promise((resolve, reject)=>{
            API.delete(apiName, path, options)
            .then((response) => {
                resolve(this.decorator.response(response));
            })
            .catch( async (error) => {
                if(this.shouldRetry()){
                    try{
                        await this.put(...arguments);
                    }catch(e){
                        error = e;
                    }
                }
                reject(error);
            });
        }));
    };
}
function HttpClientFactory(){
    return (new HttpClient());
}
export default HttpClientFactory;