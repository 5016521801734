import ENDPOINTS_PATHS from './ENDPOINTS_PATHS';

function Endpoints(){
    this.setPaths = (paths) => {
        this.paths = paths;
    };
    this.buildPath = (path) => {

        let outputPath = path;
        for(let k=0; k < Object.keys(this.paths).length; k++){
            let pathKey = Object.keys(this.paths)[k];
            let regex = pathKey.replace(/[.*+?^${}()|[\]\\/]/g, '\\$&').replace(/(:[0-9a-zA-Z_]+)/g, "(.+)");
            let matches = (new RegExp('^'+regex+'$', 'g')).exec(path);
            if(matches){
                matches.splice(0, 1);
                outputPath = this.paths[pathKey];
                for(let i = 0; i < matches.length; i++ ){
                    outputPath = outputPath.replace(/(:[0-9a-zA-Z_]+)/, matches[i]);
                }
                break;
            }
        }
        
        return outputPath;
    };
    this.setPaths(ENDPOINTS_PATHS);
}
export default (new Endpoints());