import React from 'react';
import PropTypes from 'prop-types';

class InputSearch extends React.Component{
    constructor(props){
        super(props);
        this.state = {};
    }
    handleChange = (event) => {
        this.props.onChange(this.props.name, event.target.value);
    }
    render(){
        const className = this.props.className;

        return(
            <div className={`field ${className ? className : ""}`}>
                <p className="control has-icons-left">
                    <input 
                        className="input" 
                        type="text" 
                        placeholder="Search" 
                        name={this.props.name} 
                        value={this.props.value} 
                        onChange={this.handleChange} 
                    />
                    <span className="icon is-small is-left">
                    <i className="fas fa-search"></i>
                    </span>
                </p>
            </div>
        );
    }
}
InputSearch.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string,
};
export default InputSearch;