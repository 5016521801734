import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import { Router, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import LoginPage from './pages/Auth/LoginPage';
import ForgotPasswordPage from './pages/Auth/ForgotPasswordPage';
import ChangePasswordPage from './pages/Auth/ChangePasswordPage';

import DefaultLayout from './layouts/DefaultLayout';
import ProfilePage from './pages/ProfilePage';
import CareFormsPage from './pages/CareForms/CareFormsPage';
import CareFormDetailsPage from './pages/CareForms/CareFormDetailsPage';

import Error404Page from './pages/Error404Page';

import consumeApp from './providers/consumeApp';
import { MyRoute } from './core/router';
import Loading from './shared/Loading';
import AmplifyConfigFactory from './AmplifyConfigFactory';
import './App.scss';

const history = createBrowserHistory();

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        AmplifyConfigFactory.init();
        props.checkCurrentUser();
        props.getWebClientInfo();
    }
    render() {
        return (
            <Router history={history} >
                {this.props.checkingAuthentication ?
                    <div className="container is-fluid fullheight has-background-light" >
                        <Loading wrapperClass="fullheight" />
                    </div>
                    :
                    <Switch>
                        {this.props.isLoggedIn && this.props.authUser.challengeName === 'NEW_PASSWORD_REQUIRED' ?
                            <MyRoute exact path='/change-password' component={ChangePasswordPage} private redirectTo='/login' />
                            :
                            <Redirect from='/change-password' to='/' />
                        }

                        {this.props.isLoggedIn && this.props.authUser.challengeName === 'NEW_PASSWORD_REQUIRED' &&
                            <Redirect to={"/change-password"} />
                        }

                        {/* IF SMS 2FA IS REQUIRED LATER
                        {this.props.isLoggedIn && this.props.authUser.challengeName === 'SMS_MFA' ?
                            <MyRoute exact path='/confirm-2fa' component={Confirm2FAPage} private redirectTo='/login' />
                        : 
                            <Redirect from='/confirm-2fa' to='/' />
                        }

                        {this.props.isLoggedIn && this.props.authUser.challengeName === 'SMS_MFA' &&
                            <Redirect to='/confirm-2fa' />
                        } */}

                        <Redirect exact from='/' to={this.props.isLoggedIn ? "/careforms" : "/login"} />
                        <MyRoute exact path='/login' component={LoginPage} onlyPublic redirectTo='/careforms' />
                        <MyRoute exact path='/forgot-password' component={ForgotPasswordPage} onlyPublic redirectTo='/careforms' />

                        <MyRoute path='/' component={DefaultLayout} private redirectTo="/login" >
                            <Switch>
                                <MyRoute exact path='/careforms' component={CareFormsPage} private redirectTo='/login' />
                                <MyRoute exact path='/careforms/:id' component={CareFormDetailsPage} private redirectTo='/login' />
                                <MyRoute exact path='/profile' component={ProfilePage} private redirectTo='/login' />
                                <MyRoute path="/" component={Error404Page} />
                            </Switch>
                        </MyRoute>
                    </Switch>
                }
            </Router>
        );
    }
}
export default consumeApp(App);
