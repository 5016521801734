import React from "react";
import Modal from "./Modal/Modal";

class InactivityAlert extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            counter: props.startCounter ? props.startCounter : 60
        };
    }
    componentDidUpdate(prevProps){
        if(this.props.startCounter !== prevProps.startCounter){
            this.setState({counter: this.props.startCounter});
        }
        if(this.props.isOpenInactivityAlert && !prevProps.isOpenInactivityAlert){
            this.setState({counter: this.props.startCounter ? this.props.startCounter : 60});
            this.decrement();
        }
    }
    decrement = () => {
        if(this.state.counter < 1) {
            return;
        }
        this.setState({counter: this.state.counter - 1});
        setTimeout(() => {
            this.decrement();
        }, 1000);
    }
    render(){
        return(
            <Modal isOpen={this.props.isOpenInactivityAlert} onClose={this.props.onClose} >
                <div className="box">
                    <p>You have been inactive for too long. You will be automatically logged out in <b>{this.state.counter} seconds</b>.</p>
                    <div className="has-text-centered">
                        <button className="button is-primary is-small" onClick={this.props.onClose} >
                            Continue
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }
}
export default InactivityAlert;