import Amplify from "aws-amplify";
import awsconfigTest from "./config/aws-config";
import awsconfigUat from "./config/aws-config.uat";
import awsconfigProduction from "./config/aws-config.production";

const AmplifyConfigFactory = function () {
    this.env = null;
    this.init = (env = null) => {
        return Amplify.configure(this.getConfig(env));
    };
    this.getEnv = () => {
        return this.env ? this.env :
            process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : "test";
    };
    this.setEnv = (env) => {
        this.env = env;
    };
    this.getConfig = (env = null) => {

        env = env ? env : this.getEnv();

        let awsconfig = awsconfigTest;
        switch (env) {
            case "production":
                awsconfig = awsconfigProduction;
                break;
            case "uat":
                awsconfig = awsconfigUat;
                break;

            case "test":
            case "development":
            default:
                awsconfig = awsconfigTest;
                break;
        }

        awsconfig = this.setAccessTokenForEndpoints(awsconfig);

        return awsconfig;
    };
    this.setAccessTokenForEndpoints = (awsconfig) => {
        return {
            ...awsconfig,
            aws_cloud_logic_custom: awsconfig.aws_cloud_logic_custom.map((endpoint) => {
                return {
                    ...endpoint,
                    custom_header: async () => {
                        return {
                            Authorization: `Bearer ${(await Amplify.Auth.currentSession()).getAccessToken().getJwtToken()}`
                        };
                    }
                };
            })
        };
    };
}
export default (new AmplifyConfigFactory());