import React from 'react';
import PropTypes from 'prop-types';

class ConditionViewCareForm extends React.Component{
    constructor(props){
        super(props);
        this.state = {};
    }
    handleUndo = () => {
        this.props.onChange({
            ...this.props.value,
            status: null
        });
    }
    render(){
        
        const {value} = this.props;

        return(
            <tr className="conditionViewCareForm" >
                <td className="is-valign-middle">
                    {value.hccCode}: {value.hccDescription}
                </td>
                <td className="is-valign-middle">
                    <div>
                        {value.icd10}
                    </div>
                </td>
                <td className="is-valign-middle">
                    <div>
                        {value.treatmentPlan}<br/>
                        {value.treatmentPlanNotes}
                    </div>
                </td>
                {/* <td className="is-valign-middle">
                    <div>{value.referralName}</div>
                    <div>{value.referralSpeciality}</div>
                </td> */}
                <td className="is-valign-middle">
                    <button className="button is-warning is-small is-fullwidth" onClick={this.handleUndo} >
                        UNDO
                    </button>
                </td>
            </tr>
        );
    }
}
ConditionViewCareForm.propTypes = {
    value: PropTypes.shape({
        hccCode: PropTypes.string,
        hccDescription: PropTypes.string,
        icd10: PropTypes.string,
        treatmentPlan: PropTypes.string,
        treatmentPlanNotes: PropTypes.string,
        status: PropTypes.string,
    })
}
export default ConditionViewCareForm;