const awsconfig = {
    "storage": sessionStorage,
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:0c82fb62-b515-4df3-a9e8-e3e3e1ce029c",
    "aws_cognito_region": "us-east-1",
    "aws_user_files_s3_bucket": "provxp-care-forms-production",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_3gpbbdOuA",
    "aws_user_pools_web_client_id": "6acimt3ipvd4ohgrmgfhgk38r9",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "providerApi",
            "endpoint": "https://gnl3bi3al8.execute-api.us-east-1.amazonaws.com/Prod",
            "region": "us-east-1"
        }
    ]
};
export default awsconfig;
