import React from 'react';
import PropTypes from 'prop-types';
import Input from '../../../shared/Input';
import Select from '../../../shared/Select';
import LK_STATUS from '../../../shared/constants/LK_STATUS';
import LK_TREATMENT_PLANS from '../../../shared/constants/LK_TREATMENT_PLANS';

class ConditionCareForm extends React.Component{
    constructor(props){
        super(props);
        this.state = {};
    }
    handleChange = (name, value) => {
        this.props.onChange({
            ...this.props.value,
            [name]: value
        });
    }
    handleChangeInput = (event) => {
        this.handleChange(event.target.name, event.target.value);
    }
    render(){
        
        const value = this.props.value ? this.props.value : {};
        const validations = this.props.validations ? this.props.validations : {};

        return(
            <tr className="conditionCareForm">
                <td className="is-valign-middle">
                    {value.hccCode}: {value.hccDescription}
                </td>
                <td className="is-valign-middle">
                    <Input 
                        name="icd10" 
                        placeholder="Enter ICD10 Code" 
                        className="is-fullwidth"
                        value={value.icd10}
                        onChange={this.handleChangeInput}
                        validation={validations.icd10}
                    />
                </td>
                <td className="is-valign-middle">
                    <Select 
                        placeholder={"Select Treatment Plan"}
                        name="treatmentPlan" 
                        options={LK_TREATMENT_PLANS} 
                        className="is-fullwidth"
                        value={value.treatmentPlan}
                        onChange={this.handleChange}
                        validation={validations.treatmentPlan}
                    />
                    <Input 
                        name="treatmentPlanNotes" 
                        placeholder="Enter Treatment Plan Notes" 
                        className="is-fullwidth"
                        value={value.treatmentPlanNotes}
                        onChange={this.handleChangeInput}
                        validation={validations.treatmentPlanNotes}
                    />
                </td>
                {/* <td className="is-valign-middle">
                    <Input 
                        name="referralName" 
                        placeholder="Referral name" 
                        className="is-fullwidth"
                        value={value.referralName}
                        onChange={this.handleChangeInput}
                        // validation={validations.referralName}
                    />
                    <Input 
                        name="referralSpeciality" 
                        placeholder="Referral speciality" 
                        className="is-fullwidth"
                        value={value.referralSpeciality}
                        onChange={this.handleChangeInput}
                        // validation={validations.referralSpeciality}
                    />
                </td> */}
                <td className="is-valign-middle" style={{minWidth: 160}}>
                    <Select 
                        placeholder={"Pending"}
                        name="status" 
                        options={LK_STATUS} 
                        className="is-fullwidth"
                        value={value.status}
                        onChange={this.handleChange}
                        validation={validations.status}
                    />
                </td>
            </tr>
        );
    }
}
ConditionCareForm.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.shape({
        hccCode: PropTypes.string,
        hccDescription: PropTypes.string,
        icd10: PropTypes.string,
        treatmentPlan: PropTypes.string,
        treatmentPlanNotes: PropTypes.string,
        status: PropTypes.string,
    }),
    validations: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
            hccCode: PropTypes.shape({
                type: PropTypes.string,
                message: PropTypes.string,
            }),
            hccDescription: PropTypes.shape({
                type: PropTypes.string,
                message: PropTypes.string,
            }),
            icd10: PropTypes.shape({
                type: PropTypes.string,
                message: PropTypes.string,
            }),
            treatmentPlan: PropTypes.shape({
                type: PropTypes.string,
                message: PropTypes.string,
            }),
            treatmentPlanNotes: PropTypes.shape({
                type: PropTypes.string,
                message: PropTypes.string,
            }),
            status: PropTypes.shape({
                type: PropTypes.string,
                message: PropTypes.string,
            }),
        })
    ]),
}
export default ConditionCareForm;