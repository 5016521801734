import React from 'react';
import Header from './partials/Header';
import MessagesProvider from '../shared/Messages/MessagesProvider';
import Messages from '../shared/Messages/Messages';
import EnvironmentBanner from '../shared/EnvironmentBanner';
import AmplifyConfigFactory from '../AmplifyConfigFactory';

class DefaultLayout extends React.Component {
	render(){
		return(
			<MessagesProvider>
				<div className='App fullheight is-fluid'>
					<Header />
					<EnvironmentBanner env={AmplifyConfigFactory.getEnv()} />
					<Messages />
					<div className='container is-fluid fullheight-body px-0 pt-1 has-background-light'>
						{this.props.children && this.props.children}
					</div>
				</div>
			</MessagesProvider>
		);
	}
}
export default DefaultLayout;