var uuid4 = require('uuid4');

const ActivityUtils = {
    syncSetup: (user_id, saveCb, syncFrequency) => {
        window.ActivityUtils = {
            user_id: user_id,
            saveCb: saveCb,
            syncFrequency: syncFrequency
        };
        
        ActivityUtils.syncUserActivity(user_id, saveCb, syncFrequency);
    },
    syncRemote: (user_id) => {
        ActivityUtils.syncUserActivity(user_id, window.ActivityUtils.saveCb, window.ActivityUtils.syncFrequency);
    },
    push: (userId, log) => {
        let eventTimestamp = (new Date()).getTime();
        let ActivityTrackerLogs = localStorage.getItem('ActivityTracker.'+userId);
        ActivityTrackerLogs = ActivityTrackerLogs ? JSON.parse(ActivityTrackerLogs) : [];
        
        var eventId = uuid4();

        const newLog = {
            ...log,
            eventId: eventId,
            timestamp: eventTimestamp,
            userAgent: navigator.userAgent,
            userId: userId
        };

        ActivityTrackerLogs = [
            ...ActivityTrackerLogs, 
            newLog,
        ];

        localStorage.setItem('ActivityTracker.'+userId, JSON.stringify(ActivityTrackerLogs));
        
        return newLog;
    },
    syncUserActivity: (userId, saveLogsFunc = null, syncFrequency = 30) => {
        // 1 - Read localStorage, and wipe it
        let ActivityTrackerLogs = localStorage.getItem('ActivityTracker.'+userId);
        if(ActivityTrackerLogs){
            localStorage.removeItem('ActivityTracker.'+userId);
        }
        ActivityTrackerLogs = ActivityTrackerLogs ? JSON.parse(ActivityTrackerLogs) : [];
        
        // 2 - Check if there are activity to sync for this user in this device:
        if(ActivityTrackerLogs.length > 0){
            //IF saving failed prepend ActivityTrackerLogs on the localStorage again, so we can retry saving them next time:
            if(saveLogsFunc){
                saveLogsFunc(ActivityTrackerLogs, ()=>{
                    ActivityUtils.prependLogs(userId, ActivityTrackerLogs);
                });
            }
        }

        // 3 - re-execute the same process after 30sec(syncFrequency)
        setTimeout(() => {
            ActivityUtils.syncUserActivity(userId, saveLogsFunc, syncFrequency);
        }, syncFrequency * 1000);
    },
    prependLogs: (userId, logsToPrepend) => {
        let ActivityTrackerLogs = localStorage.getItem('ActivityTracker.'+userId);
        ActivityTrackerLogs = ActivityTrackerLogs ? JSON.parse(ActivityTrackerLogs) : [];
        ActivityTrackerLogs = [...logsToPrepend, ...ActivityTrackerLogs];
        localStorage.setItem('ActivityTracker.'+userId, JSON.stringify(ActivityTrackerLogs));

        return ActivityTrackerLogs;
    }
};
export default ActivityUtils;