export default [
	{
		code: 'username',
		element_type: 'input',
		rules: {
			required: {
				type: 'error',
				error_type: 'hard',
				message: 'This field is required',
				rule: ['required'],
			}
		}
	},
	{
		code: 'code',
		element_type: 'input',
		rules: {
			required: {
				type: 'error',
				error_type: 'hard',
				message: 'This field is required',
				rule: ['required'],
			},
		}
	},
	{
		code: 'email',
		element_type: 'input',
		rules: {
			required: {
				type: 'error',
				error_type: 'hard',
				message: 'Email is required to reset your password.',
				rule: ['required'],
			},
			email: {
				type: 'error',
				error_type: 'hard',
				message: 'This is not a valid email.',
				rule: ['email'],
			}
		}
	},
	{
		code: 'new_password',
		element_type: 'input',
		rules: {
			required: {
				type: 'error',
				error_type: 'hard',
				message: 'This field is required',
				rule: ['required'],
			},
			password:{
				type: 'error',
				error_type: 'hard',
				message: 'Password does not meet requirements.',
				rule: ['password']
			},
		}
	},
];
