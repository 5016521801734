export default [
    {
		code: 'newPassword',
		element_type: 'input',
		rules: {
			required: {
				type: 'error',
				error_type: 'hard',
				message: 'Password cannot be empty.',
				rule: ['required'],
			},
			password:{
				type: 'error',
				error_type: 'hard',
				message: 'Password does not meet requirements.',
				rule: ['password']
			},
		}
	},
	{
		code: 'confirmNewPassword',
		element_type: 'input',
		rules: {
			required: {
				type: 'error',
				error_type: 'hard',
				message: 'Password cannot be empty.',
				rule: ['required'],
			},
			password:{
				type: 'error',
				error_type: 'hard',
				message: 'Password does not meet requirements.',
				rule: ['password']
			},
			equalTo:{
				type: 'error',
				error_type: 'hard',
				message: 'Password does not match.',
				rule: ['equalTo', 'newPassword']
			},
		}
	},
	
];
