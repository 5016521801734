export default [
    {
        value: "",
        label: "Select Status"
    },
    {
        value: "Accept",
        label: "Accept"
    },
    {
        value: "Dismiss",
        label: "Dismiss"
    }
];