const Rules = {
	required: (value, data) => {
		if (!empty(value, true) || value===0) {
			return true;
		}
		return false;
	},
	password: (value, data) => {
		if (empty(value, true)) return true;
		
		// AWS Cognito Password Requirements with min length of 8. 
		var rgx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\]{}\-“!@#%&/,><:;|_~`])\S{8,256}$/;

		if (isString(value)) {
			return rgx.test(value);
		}

		if (Array.isArray(value)) {
			let isOneValidAtLeast = false;
			value.map((el) => {
				if (rgx.test(el.email)) {
					isOneValidAtLeast = true;
				}

				return el;
			});

			return isOneValidAtLeast;
		}

		return false;
	},
	phone: (value, data) => {
		if (empty(value, true)) return true;

		var rgx = /^\+([0-9]){5,}$/;

		//if it is a string than it means we have the simple value
		if (isString(value)) {

			return rgx.test(value);
		}

		if (Array.isArray(value)) {
			let isOneValidAtLeast = false;
			value.map((el) => {
				if (rgx.test(el.phone)) {
					isOneValidAtLeast = true;
				}
				return el;
			});

			return isOneValidAtLeast;
		}

		return false;

	},
	mobile: (value, data) => {
		if (empty(value, true)) return true;
		// toDo('Rules.mobile');
	},
	email: (value, data) => {
		if (empty(value, true)) return true;

		var rgx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		//if it is a string than it means we have the simple value
		if (isString(value)) {
			return rgx.test(value);
		}

		if (Array.isArray(value)) {
			let isOneValidAtLeast = false;
			value.map((el) => {
				if (rgx.test(el.email)) {
					isOneValidAtLeast = true;
				}

				return el;
			});

			return isOneValidAtLeast;
		}

		return false;

	},
	equalTo: (value, data, equalToField) => {
		if (empty(value, true)) return true;
		if(value === data[equalToField] ){
			return true;
		}
		return false;
	},
	numeric: (value, data) => {
		if (empty(value, true)) return true;
		if(isNaN(value)) return false;
		return true;
	},
	naturalNumber: (value, data) => {
		if (empty(value, true)) return true;
		// toDo('Rules.maxLennumericgth');
	},
	min: (value, data, min) => {
		if (empty(value, true)) return true;
		// toDo('Rules.min (it can validate only numbers and dates)', value, data, min);
	},
	greaterThan: (value, data, min) => {
		if (empty(value, true)) return true;
		// toDo('Rules.greaterThan is not completed (it can validate only numbers and dates)', value, data, min);
		if (parseInt(value, 10) > parseInt(min, 10)) {
			return true;
		} else {
			return false;
		}
	},
	lessThan: (value, data, min) => {
		if (empty(value, true)) return true;
		// toDo('Rules.lessThan is not completed (it can validate only numbers and dates)', value, data, min);
		if (parseInt(value, 10) < parseInt(min, 10)) {
			return true;
		} else {
			return false;
		}
	},
	max: (value, data, max) => {
		if (empty(value, true)) return true;

		if(max instanceof Date){ // date
			value = new Date(value);
			return max > value;
		}else if(!isNaN(max)){ //Number
			return Number(max) > Number(value);
		}
		
		return max > value;
	},
	between: (value, data) => {
		if (empty(value, true)) return true;
		// toDo('Rules.between (it can validate only numbers and dates)');
	},
	alphaNumeric: (value, data) => {
		if (empty(value, true)) return true;
		// toDo('Rules.alphaNumeric');
	},
	onlyAlpha: (value, data) => {
		if (empty(value, true)) return true;
		// toDo('Rules.onlyAlpha');
		return true;
	},
	minLength: (value, data, min) => {
		if (empty(value, true)) return true;
		if (min <= value.length) {
			return true;
		}
		return false;
	},
	maxLength: (value, data, max) => {
		if (empty(value, true)) return true;
		if (value.length <= max) {
			return true;
		}
		return false;
	},
	lengthBetween: (value, data, min, max) => {
		if (empty(value)) value = '';
		if (min <= value.length && value.length <= max) {
			return true;
		}
		return false;
	},
	iban: (value, data) => {
		if (empty(value, true)) return true;
		// toDo('Rules.iban');
	},
	ip: (value, data) => {
		if (empty(value, true)) return true;
		// toDo('Rules.ip');
	},
	creditCard: (value, data) => {
		if (empty(value, true)) return true;
		// toDo('Rules.creditCard');
	},
	date: (value, data) => {
		if (empty(value, true)) return true;
		// toDo('Rules.date');
	},
	datetime: (value, data) => {
		if (empty(value, true)) return true;
		// toDo('Rules.datetime');
	},
	time: (value, data) => {
		if (empty(value, true)) return true;
		// toDo('Rules.time');
	},
	customRegex: (value, data, myRegex) => {
		if (empty(value, true)) return true;
		return value.match(myRegex);
	},
	customRule: (value, data, ruleFunction) => {
		return ruleFunction(value, data, Rules);
	},	
	customRegexICD10: (value, data) => {
		if (empty(value, true)) return true;

		if (value.endsWith(".")) return false;		

		var rgx = /^(?=.{3,8}$)[A-TV-Z]{1}[0-9]{1}[0-9AB]{1}\.?[0-9A-TV-Z]{0,4}$/i;

		if (isString(value)) {
			return rgx.test(value);
		}
	}
};
export default Rules;

function isString(value) {
	if (typeof value === 'string' || value instanceof String) {
		return true;
	}
	return false;
}
// function toDo(msg, ...args) {
// 	if (typeof msg !== 'string') msg = 'To be implemented';
// 	else msg = 'To be implemented: ' + msg;
// 	console.log('%c ' + msg, 'background: #222; color: #bada55', ...args);
// }
function empty(v, trim = false) {
	/*
	 * Il valore '0'(zero) NON e un empty (Diverso da PHP)
	 * */

	if (Array.isArray(v) && v.length === 0) {
		return true;
	} else if (Array.isArray(v) && v.length > 0) {
		return false;
	}

	if (trim && typeof v === 'string') {
		v = v.trim();
	}
	if (typeof v === 'undefined' || v === null || v === '' || (typeof v === 'object' && v.constructor === 'Object' && Object.keys(v).length === 0)) {
		return true;
	}
	return false;
}