import React from 'react';
import { Auth } from 'aws-amplify';
import { NavLink } from '../../core/router';
import consumeApp from '../../providers/consumeApp';
import Input from '../../shared/Input';
import withValidation from '../../validations/withValidation';
import LoginFormRules from '../../validations/FormRules/LoginFormRules';
import MessageInline from '../../shared/MessageInline';

class LoginPage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            username: '',
            password: '',
            newPassword: '',
            message: null,
        };
    }
    handleChange = (event) => {
        this.setState({
            [`${event.target.name}`]: event.target.value
        });

        //Trigger validation for new updates:
        this.props.validateLive({
            ...this.state,
            [`${event.target.name}`]: event.target.value
        });
    }
    handleSubmit = (event) => {
        event.preventDefault();
        
        //Trigger validation before submit:
        if (!this.props.validate(this.state)) return;
        
        this.setState({
            loading: true
        });

        try {
            
            Auth.signIn(this.state.username.toLowerCase(), this.state.password)
            .then((user) => {
                this.props.login(user);
                
                this.setState({
                    loading: false,
                    message: null,
                });
            }).catch( (error) => {
                this.setState({
                    loading: false,
                    message: {
                        type: 'error',
                        text: error.message
                    }
                });
            });

        } catch (error) {
            this.setState({
                loading: false
            });
        }
    }
    render(){
        return(
            <div className='App fullheight'>
                <div className='container is-fluid is-paddingless fullheight-body'> 
                    <div className='columns is-vcentered is-centered fullheight'>
                        <div className='column is-3'>
                            <div className='box'>
                                <form id="login-form" method='POST' onSubmit={this.handleSubmit} >
                                    
                                    {this.state.message && 
                                        <MessageInline {...this.state.message} />
                                    }

                                    <figure className="image is-350x150">
                                        <img src="../../docOS.350.150.dark.png" alt="docos logo"/>
                                    </figure>
                                    <Input 
                                        label='Email'
                                        name='username'
                                        value={this.state.username}
                                        onChange={this.handleChange}
                                        validation={this.props.validations.username} 
                                    />
                                    <Input 
                                        label='Password'
                                        type='password'
                                        name='password'
                                        value={this.state.password}
                                        onChange={this.handleChange}
                                        validation={this.props.validations.password} 
                                    />
                                    <div className="field">
                                        <button className={`button is-primary is-fullwidth ${this.state.loading ? 'is-loading' : ''}`} type='submit' >
                                            Log in
                                        </button>
                                    </div>
                                    <div className="columns is-mobile">
                                        <div className="column has-text-centered">
                                            <NavLink to='/forgot-password' className="has-text-grey is-size-7">Forgot Password?</NavLink>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default consumeApp(withValidation(LoginPage, LoginFormRules));