import React from 'react';
import PropTypes from 'prop-types';
import './EnvironmentBanner.scss';

const EnvironmentBanner = (props) => {
    return(
        props.env === 'production' ? null :
        <div id="env-banner" className={props.env}>
            <p>
                <b>Attention:</b> You are in <span id="env-name">{props.env.toUpperCase()}</span> environment.
            </p>
        </div>
    );
};

EnvironmentBanner.propTypes = {
    env: PropTypes.string.isRequired
};
export default EnvironmentBanner;