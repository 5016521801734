import React from 'react';
import {withRouter} from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { NavLink } from '../../core/router';
import consumeApp from '../../providers/consumeApp';
import Input from '../../shared/Input';
import withValidation from '../../validations/withValidation';
import ForgotPasswordFormRules from '../../validations/FormRules/ForgotPasswordFormRules';

class ForgotPasswordPage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            step: 'enter_email',
            email: '',
            code: '',
            new_password: '',
        };
    }
    handleChange = (event) => {
        this.setState({
            [`${event.target.name}`]: event.target.value
        });

        //Trigger validation for new updates:
        // this.props.validateLive({
        //     ...this.state,
        //     [`${event.target.name}`]: event.target.value
        // });
    }
    handleSubmit = (event) => {
        event.preventDefault();
        //if (!this.props.validate(this.state)) return;
        
        this.setState({
            loading: true
        });

        try {
            switch(this.state.step){
                case 'verification_code':
                    Auth.forgotPasswordSubmit(
                        this.state.email, 
                        this.state.code, 
                        this.state.new_password
                    ).then((data) => {
                        this.setState({
                            loading: false,
                            message: null,
                        });
                        // Redirect to login page:
                        this.props.history.push('/login');
                    }).catch( (error) => {
                        this.setState({
                            loading: false,
                            message: error.message
                        });
                    });
                break;
                
                case 'enter_email':
                default:
                    Auth.forgotPassword(this.state.email)
                    .then((data) => {
                        this.setState({
                            loading: false,
                            step: 'verification_code',
                            message: null,
                        });
                    }).catch((error) => {
                        this.setState({
                            loading: false,
                            message: error.message
                        });
                    });
                break;
            }

        } catch (error) {
            this.setState({
                loading: false
            });
        }
    }
    render(){
        return(
            <div className='App fullheight'>
                <div className='container is-fluid is-paddingless fullheight-body'> 
                    <div className='columns is-vcentered is-centered fullheight'>
                        <div className='column is-3'>
                            <div className='box'>
                                <form id="forgotPassword-form" method='POST' onSubmit={this.handleSubmit} >
                                    {this.state.message &&
                                    <article className='message is-danger'>
                                        <div className='message-body'>
                                            {this.state.message}
                                        </div>
                                    </article>}

                                    <figure className="image is-350x150">
                                        <img src="../../docOS.350.150.dark.png" alt="docos logo"/>
                                    </figure>

                                    {this.state.step === 'enter_email' &&
                                        <Input 
                                            label='Email'
                                            name='email'
                                            value={this.state.email}
                                            onChange={this.handleChange}
                                            validation={this.props.validations.email} 
                                        />
                                    }
                                    {this.state.step === 'verification_code' &&
                                        <Input 
                                            label='Code'
                                            name='code'
                                            value={this.state.code}
                                            onChange={this.handleChange}
                                            validation={this.props.validations.code} 
                                        />
                                    }
                                    {this.state.step === 'verification_code' &&
                                        <Input 
                                            label='New password'
                                            type='password'
                                            name='new_password'
                                            value={this.state.new_password}
                                            onChange={this.handleChange}
                                            validation={this.props.validations.new_password} 
                                        />
                                    }

                                    {this.state.step === 'enter_email' &&
                                        <div className='field'>
                                            <div className='control'>
                                                <NavLink to='/login' >Back to Login</NavLink>
                                            </div>
                                        </div>
                                    }

                                    <div className='field'>
                                        <div className='control'>
                                            <button className={`button is-primary is-fullwidth is-link ${this.state.loading ? 'is-loading' : ''}`} type='submit' >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default withRouter(consumeApp(withValidation(ForgotPasswordPage, ForgotPasswordFormRules)));