export default [
    {
        value: "",
        label: "Select Treatment Plan"
    },
    {
        value: "Diet",
        label: "Diet"
    },
    {
        value: "Monitor",
        label: "Monitor"
    },
    {
        value: "Rx",
        label: "Rx"
    },
    {
        value: "Referral",
        label: "Referral"
    },
    {
        value: "Other",
        label: "Other"
    }
];