import React from 'react';
import PropTypes from 'prop-types';
import ActivityUtils from './ActivityUtils';

class ActivityTracker extends React.Component{
    constructor(props){
        super(props);
        this.state = {};
    }
    componentDidMount(){
        if(this.props.events.indexOf('render') > -1){
            this.saveLog('render');
        }
    }
    saveLog(eventType){
        let newLog = ActivityUtils.push(this.props.userId, {
            tag: this.props.tag,
            event: eventType
        });
        if(this.props.immediate){
            ActivityUtils.syncRemote(this.props.userId);
        }
        return newLog;
    }
    decoratedOnClick = (childProps) => (...args) => {
        if(typeof childProps.onClick === 'undefined') return childProps.onClick;

        if(this.props.events.indexOf('click') > -1){
            args.push(this.saveLog('click', ...args));
        }
        return childProps.onClick(...args);
    }
    render(){
        return (
            <React.Fragment>
                {React.Children.map(this.props.children, (child) => 
                    React.cloneElement(child, {
                        onClick: this.decoratedOnClick(child.props)
                    })
                )}
            </React.Fragment>
        );
    }
}
ActivityTracker.defaultProps = {
    events: ['render']
};
ActivityTracker.propTypes = {
    events: PropTypes.arrayOf(PropTypes.oneOf(['click', 'render'])),
    tag: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
};
export default ActivityTracker;