const awsconfig = {
    "storage": sessionStorage,
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:47a1abf7-562a-4c7a-89fe-ffbd2a41cbca",
    "aws_cognito_region": "us-east-1",
    "aws_user_files_s3_bucket": "provxp-care-forms-uat",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_tyFEHuM7A",
    "aws_user_pools_web_client_id": "35ocv379rg9flihg2ki0lu1mhh",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "providerApi",
            "endpoint": "https://jzjv3wxk7c.execute-api.us-east-1.amazonaws.com/Prod",
            "region": "us-east-1"
        }
    ]
};
export default awsconfig;
