export const getUserClientInfo = async (cb = null) => {
    window.webClientInfo = null;
    let retries = 0;
    const whoIsMe = async () => {
        return await fetch('https://www.cloudflare.com/cdn-cgi/trace')
        .then( async (response) => {
            if(response.ok){
                await response.text().then( async (text) => {
                    window.webClientInfo = {};
                    text.split("\n").forEach((param)=>{
                        if(param === '' || param === undefined || param === null) return;
                        let [k, v] = param.split("=");
                        window.webClientInfo[k] = v;
                    });
                    if(cb) cb(window.webClientInfo);
                })
            }else{
                if(retries < 3){
                    retries++;
                    await timeout(3000);
                    await whoIsMe();
                }
            }
        }).catch( async (error) => {
            if(retries < 3){
                retries++;
                await timeout(3000);
                await whoIsMe();
            }
        });
    };

    return await whoIsMe();
};
function timeout(ms){
    return new Promise(resolve => setTimeout(resolve, ms));
}