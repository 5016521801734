import React from 'react';
import Validate from './Validate';

function withValidation(WrappedFormComponent, FormRules){
    return class withValidation extends React.Component{
        constructor(props){
            super(props);
            this.displayName = 'withValidation';
            this.state = {};

            // Validation vars:
            this.isValidated = false;
            this.validations = true;
            // ****************
        }
        validateLive = (values) => {
            if(this.isValidated){
                return this.validateForm(values);
            }
            return true;
        }
        validate = (values = null) => {
            this.isValidated = true;
            return this.validateForm(values);
        }
        validateForm = (values = null) => {
            if (!values) return;
            
            let newValidations = Validate.check(FormRules, values);
            
            if (JSON.stringify(this.validations) !== JSON.stringify(newValidations) ) {
                this.validations = newValidations;
                this.forceUpdate();
            }
            
            return this.validations === true;
        }
        render(){
            return(
                <WrappedFormComponent 
                    {...this.props}
                    validate={this.validate}
                    validateLive={this.validateLive}
                    validations={this.validations}
				/>
            );
        }
    }
}
export default withValidation;