import React from 'react';
import PropTypes from 'prop-types';

class MessageInline extends React.Component{
    constructor(props){
        super(props);
        this.state = {};
    }
    render(){
        let textColor = "";
        let messageType = "";
        switch( this.props.type ){
            case "error":
                textColor = "has-text-danger";
                messageType = "is-danger";
            break;
            case "success":
                textColor = "has-text-success";
                messageType = "is-success";
            break;
            case "warning":
                textColor = "has-text-warning";
                messageType = "is-warning";
            break;
            default:
                textColor = "has-text-info";
                messageType = "is-info";
            break;
        }

        return(
            <article className={`message ${messageType}`} >
                <div className="message-body">
                    <span className={textColor}>
                        {this.props.text}
                    </span>
                </div>
            </article>
        );
    }
}
MessageInline.propTypes = {
    text: PropTypes.string,
    type: PropTypes.string,
};
export default MessageInline;