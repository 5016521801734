export default [
	{
		code: 'username',
		element_type: 'input',
		rules: {
			email: {
				type: 'error',
				error_type: 'hard',
				message: 'Username should be a valid email',
				rule: ['email'],
			},
			required: {
				type: 'error',
				error_type: 'hard',
				message: 'Username cannot be empty',
				rule: ['required'],
			}
		}
	},
	{
		code: 'password',
		element_type: 'input',
		rules: {
			required: {
				type: 'error',
				error_type: 'hard',
				message: 'Password cannot be empty',
				rule: ['required'],
			},
			password:{
				type: 'error',
				error_type: 'hard',
				message: 'Password does not meet requirements.',
				rule: ['password']
			},
		}
	},
];
